import React, { FC, useCallback } from 'react';
import { FormInstance } from 'antd/lib/form';
import { TType } from 'translations';
import { CreateFormItemType, SelectOptions } from 'types';
import {
  FORM_ITEM_REQUIRED_RULE,
  FORM_ITEM_50_CHARACTER_RULES,
  FORM_ITEM_10_CHARACTER_RULES,
  disableFutureDate,
  englishPatternRule,
  numberPatternRule,
  FORM_ITEM_MIN_10_CHARACTER_RULES,
} from 'utils';
import { WRAPPER_COL_SPAN_SIZE_21 } from 'ui/components';
import { UploadFile } from 'antd/lib/upload/interface';
import { CustomFormGroupWrapper } from './CustomFormGroupWrapper';

type Props = {
  t: TType;
  form?: FormInstance;
  relationshipLookups: SelectOptions;
};

export const ProfileUpdateDependentFormGroup: FC<Props> = ({ t, form, relationshipLookups }) => {
  const onAttachmentChange = useCallback(
    (formListIndex: number, file?: UploadFile | UploadFile[]) => {
      if (form) {
        form.setFields([
          {
            name: ['dependentDetails', formListIndex, 'dependentAttachmentList'],
            value: file,
          },
        ]);
      }
    },
    [form]
  );

  const dependentFormGroup = useCallback<(formListName: number) => CreateFormItemType[]>(
    (formListName) => {
      return [
        {
          id: `${0}`,
          label: 'Full name',
          name: [formListName, 'fullName'],
          type: 'input',
          rules: [
            ...FORM_ITEM_REQUIRED_RULE,
            ...FORM_ITEM_50_CHARACTER_RULES,
            englishPatternRule(t),
          ],
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Enter full name',
        },
        {
          id: `${1}`,
          name: [formListName, 'erpPersonNumber'],
          type: 'input',
          hidden: true,
        },
        {
          id: `${2}`,
          label: 'Date of birth',
          name: [formListName, 'dateOfBirth'],
          type: 'date',
          disabledDate: disableFutureDate,
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Choose date of birth',
        },
        {
          id: `${3}`,
          label: 'National/IQAMA ID',
          name: [formListName, 'idNumber'],
          type: 'input',
          rules: [
            ...FORM_ITEM_REQUIRED_RULE,
            numberPatternRule(t),
            ...FORM_ITEM_10_CHARACTER_RULES,
            ...FORM_ITEM_MIN_10_CHARACTER_RULES,
          ],
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Enter national/iqama ID',
        },
        {
          id: `${4}`,
          label: 'Relationship',
          name: [formListName, 'relationship'],
          type: 'select',
          options: relationshipLookups,
          selectTheValueAsKey: true,
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Choose relationship',
        },
        {
          id: `${5}`,
          label: 'National/IQAMA ID Copy',
          name: [formListName, 'dependentAttachmentList'],
          type: 'file',
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          valuePropName: 'fileList',
          attachmentFileExtensions: '.png,.pdf',
          attachmentFileTypes: 'image/png,application/pdf',
          onFileChange: (file) => onAttachmentChange(formListName, file),
        },
      ];
    },
    [onAttachmentChange, relationshipLookups, t]
  );
  return (
    <CustomFormGroupWrapper
      t={t}
      maxRecords={10}
      formGroupItems={dependentFormGroup}
      formListName="dependentDetails"
      sectionTitle="Dependent Details"
      addButtonTitle="Add additional dependents"
      hasAtLeastOne={false}
      form={form}
    />
  );
};
