import React, { memo, FC } from 'react';
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import { TasksProvider } from '../Tasks';
import { MyWorkLayout } from './MyWorkLayout';

type Props = RouteComponentProps;

export const MyWorkComponent: FC<Props> = ({ match: { path } }) => (
  <Switch>
    <Route path={path}>
      <TasksProvider>
        <MyWorkLayout />
      </TasksProvider>
    </Route>
  </Switch>
);

export const MyWork = memo(withRouter(MyWorkComponent));
