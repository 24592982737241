import { Tooltip } from 'antd';
import React from 'react';
import { colors } from 'ui/elements';
import { StyledAppNameText, StyledAppNameWrapper } from '../AppsDrawer.styled';

export const AppNameStyle = (nameText: string) => {
  return (
    <StyledAppNameWrapper>
      <Tooltip
        placement="top"
        title={nameText}
        trigger={'hover'}
        color={colors.grayTooltip}
        getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      >
        <StyledAppNameText ellipsis>{nameText}</StyledAppNameText>
      </Tooltip>
    </StyledAppNameWrapper>
  );
};
