import React, { FC } from 'react';
import { TType } from 'translations';
import { Col } from 'antd';
import { IPersonalDetail } from 'types';
import { ProfileEntryField } from 'ui';
import { StyledProfileRowContainer } from '../../NewProfile.styled';

type Props = {
  t: TType;
  personalDetails: IPersonalDetail;
};

export const PrivateProfilePersonalDetails: FC<Props> = ({ t, personalDetails }) => {
  const {
    firstName,
    fatherName,
    grandfatherName,
    familyName,
    gender,
    dateOfBirth,
    age,
    countryOfOrigin,
    maritalStatus,
    nationalId,
  } = personalDetails;
  return (
    <StyledProfileRowContainer gutter={[25, 15]} wrap={true}>
      <Col xs={24} sm={24} md={12} lg={8}>
        <ProfileEntryField label={t('user.privateProfile.firstName')} value={firstName} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        <ProfileEntryField label={t('user.privateProfile.fatherName')} value={fatherName} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        <ProfileEntryField
          label={t('user.privateProfile.grandfatherName')}
          value={grandfatherName}
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        <ProfileEntryField label={t('user.privateProfile.familyName')} value={familyName} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        <ProfileEntryField label={t('user.privateProfile.idNumber')} value={nationalId} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        <ProfileEntryField label={t('user.privateProfile.gender')} value={gender} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        <ProfileEntryField label={t('user.privateProfile.dateOfBirth')} value={dateOfBirth} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        <ProfileEntryField label={t('user.privateProfile.age')} value={age?.toString()} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        <ProfileEntryField
          label={t('user.privateProfile.countryOfOrigin')}
          value={countryOfOrigin}
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        <ProfileEntryField label={t('user.privateProfile.maritalStatus')} value={maritalStatus} />
      </Col>
    </StyledProfileRowContainer>
  );
};
