import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { TType } from 'translations';
import Form, { useForm } from 'antd/lib/form/Form';
import { VALIDATE_MESSAGES } from 'utils';
import { IMappedProfileUpdateData, IProfileUpdateSubmitResponse } from 'types';
import {
  useGetProfileUpdateRequestLookups,
  useGetProfileUpdateRequestData,
  useSubmitProfileUpdateRequest,
} from 'api';
import { FullWidthAndHeightSpin } from 'ui';
import { notification, Spin } from 'antd';
import { uploadAttachmentItem } from 'ui/publicFunction';
import { UploadFile } from 'antd/lib/upload/interface';
import { useHistory } from 'react-router-dom';
import { useTasksContext } from 'routes/Tasks/TasksProvider';
import { mapBackProfileUpdateData, mapProfileUpdateData } from './utils';
import { ProfileUpdateFormFields } from './ProfileUpdateFormFields';

type Props = {
  t: TType;
  formName?: string;
};

const ProfileUpdateFormComponent: FC<Props> = ({ formName, t }) => {
  const { setIsSubmitLoading, setIsFormValuesChanged } = useTasksContext();
  const [isAttachmentUploadLoading, setIsAttachmentUploadLoading] = useState(false);
  const history = useHistory();
  const [form] = useForm();
  const {
    data: profileUpdateLookups,
    isError: isProfileUpdateLookupsError,
    isLoading: isProfileUpdateLookupsLoading,
  } = useGetProfileUpdateRequestLookups();
  const {
    data: profileUpdateData,
    isError: isProfileUpdateDataError,
    isLoading: isProfileUpdateDataLoading,
  } = useGetProfileUpdateRequestData(!!profileUpdateLookups);
  const {
    mutateAsync: submitForm,
    isLoading: isSubmitFormLoading,
  } = useSubmitProfileUpdateRequest();

  const mappedRequestRowData = useMemo<IMappedProfileUpdateData | undefined>(() => {
    if (profileUpdateData) {
      return mapProfileUpdateData(profileUpdateData, profileUpdateLookups?.lookups?.COUNTRIES);
    }
    return undefined;
  }, [profileUpdateData, profileUpdateLookups]);

  const submitSubFormAttachment = (
    fileList: UploadFile[][],
    submitRes: IProfileUpdateSubmitResponse,
    type: string
  ) => {
    const {
      taskId,
      processDefinitionId,
      processInstanceId,
      activityId,
      dependentDetailsIds,
      educationalDegreesIds,
      professionalCertificatesIds,
      trainingIds,
      experiencesIds,
    } = submitRes;
    let ids: number[] = [];
    if (type === 'EDUCATION' && educationalDegreesIds) {
      ids = educationalDegreesIds;
    } else if (type === 'DEPENDANT' && dependentDetailsIds) {
      ids = dependentDetailsIds;
    } else if (type === 'CERTIFICATE' && professionalCertificatesIds) {
      ids = professionalCertificatesIds;
    } else if (type === 'TRAINING' && trainingIds) {
      ids = trainingIds;
    } else if (type === 'EXPERIENCE' && experiencesIds) {
      ids = experiencesIds;
    } else {
      return;
    }

    const location = {
      pathname: `/tasks/${submitRes.processInstanceId}`,
      state: { defaultTab: '0' },
    };

    setIsAttachmentUploadLoading(true);
    fileList.forEach((items, index) => {
      if (items.length > 0 && ids[index]) {
        uploadAttachmentItem(
          taskId,
          processDefinitionId,
          processInstanceId,
          activityId,
          [items],
          [ids[index]],
          type
        );
      }
    });
    setTimeout(() => {
      setIsAttachmentUploadLoading(false);
      history.replace(location);
    }, 2000);
  };

  useEffect(
    () => {
      if (form) {
        form?.setFieldsValue(mappedRequestRowData);
      }
      return () => {
        setIsFormValuesChanged(false);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mappedRequestRowData, form]
  );

  useEffect(() => {
    if (isProfileUpdateDataError || isProfileUpdateLookupsError) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    }
  }, [isProfileUpdateDataError, isProfileUpdateLookupsError, t]);

  const onFormSubmit = (formValues: IMappedProfileUpdateData) => {
    setIsSubmitLoading(true);
    const { submitAttachments, submitData } = mapBackProfileUpdateData(
      formValues,
      mappedRequestRowData as IMappedProfileUpdateData,
      profileUpdateLookups?.lookups?.COUNTRIES
    );
    submitForm(submitData)
      .then((submitRes) => {
        submitSubFormAttachment(submitAttachments.educationalDegrees, submitRes, 'EDUCATION');
        submitSubFormAttachment(submitAttachments.experiences, submitRes, 'EXPERIENCE');
        submitSubFormAttachment(submitAttachments.dependentDetails, submitRes, 'DEPENDANT');
        submitSubFormAttachment(
          submitAttachments.professionalCertificates,
          submitRes,
          'CERTIFICATE'
        );
        submitSubFormAttachment(submitAttachments.training, submitRes, 'TRAINING');
      })
      .catch(() => {
        setIsSubmitLoading(false);
        notification.error({
          message: t('messages.error.wentWrong'),
          description: t('messages.error.tryAgainLater'),
        });
      });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name={formName}
      validateMessages={VALIDATE_MESSAGES(t)}
      onFinish={onFormSubmit}
      onChange={() => {
        setIsFormValuesChanged(true);
      }}
    >
      {isProfileUpdateDataLoading || isProfileUpdateLookupsLoading || !profileUpdateLookups ? (
        <FullWidthAndHeightSpin />
      ) : (
        <Spin spinning={isSubmitFormLoading || isAttachmentUploadLoading} delay={100}>
          <ProfileUpdateFormFields
            lookups={profileUpdateLookups}
            t={t}
            form={form}
            userDetails={mappedRequestRowData}
          />
        </Spin>
      )}
    </Form>
  );
};
export const ProfileUpdateForm = memo(ProfileUpdateFormComponent);
