import {
  IMappedProfileUpdateData,
  IProfileUpdateAttachment,
  IProfileUpdateAttachmentMapped,
  IProfileUpdateCertificate,
  IProfileUpdateCertificateMapped,
  IProfileUpdateData,
  IProfileUpdateDependentDetail,
  IProfileUpdateDependentDetailMapped,
  IProfileUpdateEducationalDegree,
  IProfileUpdateEducationalDegreeMapped,
  IProfileUpdateEmergencyContact,
  IProfileUpdateExperience,
  IProfileUpdateExperienceMapped,
  IProfileUpdateTraining,
  IProfileUpdateTrainingMapped,
  IProfileUpdateUploadAttachment,
  SelectOptions,
} from 'types';
import moment from 'moment/moment';
import { UploadFile } from 'antd/lib/upload/interface';

const convertDateToMoment = (dateString: string) => {
  return moment(dateString);
};

const convertMomentToString = (date: moment.Moment) => {
  return date.format('yyyy-MM-DD');
};

const convertFormAttachmentsToSubmitType = (
  attachments?: (IProfileUpdateAttachmentMapped | UploadFile)[],
  isDeletedSection?: boolean
) => {
  const toBeUploadedAttachments: UploadFile[] = [];
  const uploadedAttachments: IProfileUpdateAttachment[] = [];
  if (!isDeletedSection) {
    attachments?.forEach((attachment) => {
      if ('originalObject' in attachment && attachment.originalObject) {
        uploadedAttachments.push(attachment.originalObject);
      } else {
        toBeUploadedAttachments.push(attachment as UploadFile);
      }
    });
  }
  return { toBeUploadedAttachments, uploadedAttachments };
};

const convertAttachmentToUploadType = (
  attachment: IProfileUpdateAttachment
): IProfileUpdateAttachmentMapped => {
  return {
    uid: Math.random().toString(36).substring(2),
    name: `${attachment.name}`,
    status: 'done',
    url: '',
    originalObject: attachment,
  };
};

export const mapProfileUpdateData = (
  data: IProfileUpdateData,
  countries?: SelectOptions
): IMappedProfileUpdateData => {
  if (countries) {
    const country = countries.find((c) => {
      return c.label === data.country;
    });
    data = { ...data, countryId: country?.value as string };
  }
  return {
    ...data,
    age: moment().diff(data.dateOfBirth, 'years'),
    dateOfBirth: convertDateToMoment(data.dateOfBirth),
    educationalDegrees: data.educationalDegrees?.map((educationalDegree) => ({
      ...educationalDegree,
      startDate: convertDateToMoment(educationalDegree.startDate),
      endDate: convertDateToMoment(educationalDegree.endDate),
      educationalDegreeAttachmentList: educationalDegree.educationalDegreeAttachmentList?.map(
        convertAttachmentToUploadType
      ),
    })),
    experiences: data.experiences?.map(({ ...experience }) => ({
      ...experience,
      period: [
        convertDateToMoment(experience.periodFrom),
        convertDateToMoment(experience.periodTo),
      ],
      experienceAttachmentList: experience.experienceAttachmentList?.map(
        convertAttachmentToUploadType
      ),
    })),
    professionalCertificates: data.professionalCertificates?.map((certificate) => ({
      ...certificate,
      issueDate: convertDateToMoment(certificate.issueDate),
      expirationDate: convertDateToMoment(certificate.expirationDate),
      professionalCertificateAttachmentList: certificate.professionalCertificateAttachmentList?.map(
        convertAttachmentToUploadType
      ),
    })),
    training: data.training?.map((training) => ({
      ...training,
      issueDate: convertDateToMoment(training.issueDate),
      expirationDate: convertDateToMoment(training.expirationDate),
      trainingAttachmentList: training.trainingAttachmentList?.map(convertAttachmentToUploadType),
    })),
    bankDetails: data.bankDetails?.map((bankDetail) => ({
      ...bankDetail,
      bankDetailsAttachmentList: bankDetail.bankDetailsAttachmentList?.map(
        convertAttachmentToUploadType
      ),
    })),
    dependentDetails: data.dependentDetails?.map((dependent) => ({
      ...dependent,
      dateOfBirth: convertDateToMoment(dependent.dateOfBirth),
      dependentAttachmentList: dependent.dependentAttachmentList?.map(
        convertAttachmentToUploadType
      ),
    })),
  };
};

const objectsAreEqual = <T>(obj1: T, obj2: T, propList: (keyof T)[]) => {
  for (const prop of propList) {
    if (obj1[prop] !== obj2[prop]) return false;
  }

  return true;
};

const assignSectionStatus = <T>(
  submitted: T[] | undefined,
  original: T[] | undefined,
  pivotProperty: keyof T,
  compareProperties: (keyof T)[],
  handleIdNumber?: boolean
): (T & { appliedAction: string })[] => {
  if (submitted && original) {
    return original
      .map((originalItem) => {
        const match = submitted.find(
          (submittedItem) =>
            submittedItem[pivotProperty] &&
            submittedItem[pivotProperty] === originalItem[pivotProperty]
        );
        if (!match) return { ...originalItem, appliedAction: 'DELETED' };
        if (objectsAreEqual<T>(originalItem, match, compareProperties))
          return { ...match, appliedAction: 'NOT_CHANGE' };
        if (
          handleIdNumber &&
          Object.prototype.hasOwnProperty.call(match, 'idNumber') &&
          Object.prototype.hasOwnProperty.call(match, 'oldIdNumber') &&
          Object.prototype.hasOwnProperty.call(originalItem, 'idNumber') &&
          Object.prototype.hasOwnProperty.call(originalItem, 'oldIdNumber')
        ) {
          if (match['idNumber' as keyof T] !== originalItem['idNumber' as keyof T]) {
            match['oldIdNumber' as keyof T] = originalItem['idNumber' as keyof T];
          }
        }
        return { ...match, appliedAction: 'UPDATED' };
      })
      .concat(
        submitted
          .filter(
            (submittedItem) =>
              !original.some(
                (originalItem) =>
                  submittedItem[pivotProperty] &&
                  submittedItem[pivotProperty] === originalItem[pivotProperty]
              )
          )
          .map((item) => ({ ...item, appliedAction: 'CREATED' }))
      );
  }
  return [];
};

const mapBackSection = <T, R>(
  submitted: T[] | undefined,
  original: T[] | undefined,
  pivotProperty: keyof T,
  compareProperties: (keyof T)[],
  propertiesToDelete: (keyof T)[],
  convertToDateProperties: { fromProperty: unknown; toProperty: keyof R; index?: number }[],
  attachmentProperty?: keyof T & keyof R,
  handleIdNumber?: boolean
): { mappedSections: (R & { appliedAction: string })[]; submitAttachments: UploadFile[][] } => {
  const submitAttachments: UploadFile[][] = [];
  const submitSections = assignSectionStatus<T>(
    submitted,
    original,
    pivotProperty,
    compareProperties,
    handleIdNumber
  );
  const mappedSections: (R & { appliedAction: string })[] = submitSections.map((item, index) => {
    const mappedSection: R & { appliedAction: string } = ({ ...item } as unknown) as R & {
      appliedAction: string;
    };
    if (attachmentProperty) {
      const attachments = (item[attachmentProperty] as unknown) as
        | (IProfileUpdateAttachmentMapped | UploadFile)[]
        | undefined;
      const { uploadedAttachments, toBeUploadedAttachments } = convertFormAttachmentsToSubmitType(
        attachments,
        item['appliedAction'] === 'DELETED'
      );
      submitAttachments[index] = toBeUploadedAttachments;
      mappedSection[attachmentProperty] = ((uploadedAttachments.length > 0
        ? uploadedAttachments
        : undefined) as unknown) as (R & { appliedAction: string })[keyof T & keyof R];
    }
    convertToDateProperties.forEach(({ fromProperty, toProperty, index: propertyIndex }) => {
      mappedSection[toProperty] = (convertMomentToString(
        (propertyIndex !== undefined
          ? (((mappedSection[fromProperty as keyof R] as unknown) as Array<unknown>)[
              propertyIndex
            ] as unknown)
          : (mappedSection[fromProperty as keyof R] as unknown)) as moment.Moment
      ) as unknown) as (R & { appliedAction: string })[keyof R];
    });
    propertiesToDelete.forEach((property) => {
      delete mappedSection[(property as unknown) as keyof R];
    });
    return mappedSection;
  });
  return { mappedSections, submitAttachments };
};

export const mapBackProfileUpdateData = (
  submittedData: IMappedProfileUpdateData,
  originalData: IMappedProfileUpdateData,
  countries?: SelectOptions
): {
  submitData: IProfileUpdateData;
  submitAttachments: IProfileUpdateUploadAttachment;
} => {
  const {
    mappedSections: mappedEducationalDegrees,
    submitAttachments: submitEducationalDegreesAttachments,
  } = mapBackSection<IProfileUpdateEducationalDegreeMapped, IProfileUpdateEducationalDegree>(
    submittedData.educationalDegrees,
    originalData.educationalDegrees,
    'erpEducationId',
    ['degreeType', 'universityName', 'major', 'startDate', 'endDate'],
    [],
    [
      { fromProperty: 'startDate', toProperty: 'startDate' },
      { fromProperty: 'endDate', toProperty: 'endDate' },
    ],
    'educationalDegreeAttachmentList'
  );

  const {
    mappedSections: mappedExperiences,
    submitAttachments: submitExperiencesAttachments,
  } = mapBackSection<IProfileUpdateExperienceMapped, IProfileUpdateExperience>(
    submittedData.experiences,
    originalData.experiences,
    'erpWorkHistoryId',
    ['industry', 'positionTitle', 'organizationName', 'period'],
    ['period'],
    [
      { fromProperty: 'period', toProperty: 'periodFrom', index: 0 },
      { fromProperty: 'period', toProperty: 'periodTo', index: 1 },
    ],
    'experienceAttachmentList'
  );

  const {
    mappedSections: mappedProfessionalCertificates,
    submitAttachments: submitProfessionalCertificatesAttachments,
  } = mapBackSection<IProfileUpdateCertificateMapped, IProfileUpdateCertificate>(
    submittedData.professionalCertificates,
    originalData.professionalCertificates,
    'erpCertificateId',
    ['issuingOrganization', 'name', 'issueDate', 'expirationDate'],
    [],
    [
      { fromProperty: 'issueDate', toProperty: 'issueDate' },
      { fromProperty: 'expirationDate', toProperty: 'expirationDate' },
    ],
    'professionalCertificateAttachmentList'
  );

  const {
    mappedSections: mappedTrainings,
    submitAttachments: submitTrainingsAttachments,
  } = mapBackSection<IProfileUpdateTrainingMapped, IProfileUpdateTraining>(
    submittedData.training,
    originalData.training,
    'erpCertificateId',
    ['issuingOrganization', 'name', 'issueDate', 'expirationDate'],
    [],
    [
      { fromProperty: 'issueDate', toProperty: 'issueDate' },
      { fromProperty: 'expirationDate', toProperty: 'expirationDate' },
    ],
    'trainingAttachmentList'
  );

  const {
    mappedSections: mappedDependentDetails,
    submitAttachments: submitDependentDetailsAttachments,
  } = mapBackSection<IProfileUpdateDependentDetailMapped, IProfileUpdateDependentDetail>(
    submittedData.dependentDetails,
    originalData.dependentDetails,
    'erpPersonNumber',
    ['relationship', 'oldIdNumber', 'idNumber', 'fullName', 'dateOfBirth'],
    [],
    [{ fromProperty: 'dateOfBirth', toProperty: 'dateOfBirth' }],
    'dependentAttachmentList',
    true
  );

  const submitEmergencyContacts = assignSectionStatus<IProfileUpdateEmergencyContact>(
    submittedData.emergencyContacts,
    originalData.emergencyContacts,
    'erpPersonNumber',
    ['firstName', 'familyName', 'mobileNumber', 'relationship']
  );

  const submitAttachments: IProfileUpdateUploadAttachment = {
    educationalDegrees: submitEducationalDegreesAttachments,
    professionalCertificates: submitProfessionalCertificatesAttachments,
    training: submitTrainingsAttachments,
    dependentDetails: submitDependentDetailsAttachments,
    experiences: submitExperiencesAttachments,
  };

  if (countries) {
    const country = countries.find((c) => {
      return c.value === submittedData.countryId;
    });
    submittedData = { ...submittedData, country: country?.label };
  }

  if (submittedData.nationalId !== originalData.nationalId) {
    submittedData.oldNationalId = originalData.nationalId;
  }

  const submitData = {
    ...submittedData,
    age: submittedData.age,
    dateOfBirth: convertMomentToString(submittedData.dateOfBirth),
    educationalDegrees: mappedEducationalDegrees,
    experiences: mappedExperiences,
    professionalCertificates: mappedProfessionalCertificates,
    training: mappedTrainings,
    bankDetails: submittedData.bankDetails?.map((bankDetail) => {
      const convertedAttachments = convertFormAttachmentsToSubmitType(
        bankDetail.bankDetailsAttachmentList
      );
      return {
        ...bankDetail,
        bankDetailsAttachmentList:
          convertedAttachments.uploadedAttachments.length > 0
            ? convertedAttachments.uploadedAttachments
            : undefined,
      };
    }),
    dependentDetails: mappedDependentDetails,
    emergencyContacts: submitEmergencyContacts,
  };
  return { submitData, submitAttachments };
};
