import React, { useMemo } from 'react';
import { TType } from 'translations';
import { ProfileSectionContainer } from 'ui/components';
import { ProfileData } from 'types';
import { StyledPrivateProfileTabContentWrapper } from '../PublicProfile.styled';
import { IPublicProfileAttachments, PublicProfileAttachments } from './PublicProfileAttachments';

export interface IPublicProfileAttachmentSectionProps {
  t: TType;
  profileData: ProfileData;
}

export const PublicProfileAttachmentSection: React.FC<IPublicProfileAttachmentSectionProps> = ({
  t,
  profileData,
}: IPublicProfileAttachmentSectionProps) => {
  const dataProfessionalCertificates: IPublicProfileAttachments[] = useMemo(() => {
    const data: IPublicProfileAttachments[] = [];
    if (profileData.professionalCertificates) {
      profileData.professionalCertificates?.forEach((certificate) => {
        if (certificate?.professionalCertificateAttachmentList?.length > 0) {
          data.push({
            attachments: certificate.professionalCertificateAttachmentList,
            title: certificate.name,
          });
        }
      });
    }
    return data;
  }, [profileData.professionalCertificates]);

  const dataDependentDetails: IPublicProfileAttachments[] = useMemo(() => {
    const data: IPublicProfileAttachments[] = [];
    if (profileData.dependentDetails) {
      profileData.dependentDetails?.forEach((certificate) => {
        if (certificate?.dependentAttachmentList?.length > 0) {
          data.push({
            attachments: certificate.dependentAttachmentList,
            title: certificate.fullName,
          });
        }
      });
    }
    return data;
  }, [profileData.dependentDetails]);

  const dataEducationalDegree: IPublicProfileAttachments[] = useMemo(() => {
    const data: IPublicProfileAttachments[] = [];
    if (profileData.educationalDegrees) {
      profileData.educationalDegrees?.forEach((certificate) => {
        if (certificate?.educationalDegreeAttachmentList?.length > 0) {
          data.push({
            attachments: certificate.educationalDegreeAttachmentList,
            title: certificate.degreeType,
          });
        }
      });
    }
    return data;
  }, [profileData.educationalDegrees]);

  const dataExperiences: IPublicProfileAttachments[] = useMemo(() => {
    const data: IPublicProfileAttachments[] = [];
    if (profileData.experiences) {
      profileData.experiences?.forEach((experience) => {
        if (experience?.experienceAttachmentList?.length > 0) {
          data.push({
            attachments: experience.experienceAttachmentList,
            title: experience.organizationName,
          });
        }
      });
    }
    return data;
  }, [profileData.experiences]);

  const dataTraining: IPublicProfileAttachments[] = useMemo(() => {
    const data: IPublicProfileAttachments[] = [];
    if (profileData.training) {
      profileData.training?.forEach((certificate) => {
        if (certificate?.trainingAttachmentList?.length > 0) {
          data.push({
            attachments: certificate.trainingAttachmentList,
            title: certificate.name,
          });
        }
      });
    }
    return data;
  }, [profileData.training]);

  const dataBankDetails: IPublicProfileAttachments[] = useMemo(() => {
    const data: IPublicProfileAttachments[] = [];
    if (profileData.bankDetails) {
      profileData.bankDetails?.forEach((certificate) => {
        if (certificate?.bankDetailsAttachmentList?.length > 0) {
          data.push({
            attachments: certificate.bankDetailsAttachmentList,
            title: '',
          });
        }
      });
    }
    return data;
  }, [profileData.bankDetails]);

  return (
    <StyledPrivateProfileTabContentWrapper>
      <ProfileSectionContainer
        sectionName={t('profile.personal.sections.professionalCertificates')}
        isLoading={false}
        isEmpty={dataProfessionalCertificates.length <= 0}
        hideOnEmpty={false}
      >
        <PublicProfileAttachments data={dataProfessionalCertificates} />
      </ProfileSectionContainer>
      <ProfileSectionContainer
        sectionName={t('profile.personal.sections.dependentDetails')}
        isEmpty={dataDependentDetails.length <= 0}
        hideOnEmpty={false}
      >
        <PublicProfileAttachments data={dataDependentDetails} />
      </ProfileSectionContainer>
      <ProfileSectionContainer
        sectionName={t('profile.personal.sections.educationalDegree')}
        isEmpty={dataEducationalDegree.length <= 0}
        hideOnEmpty={false}
      >
        <PublicProfileAttachments data={dataEducationalDegree} />
      </ProfileSectionContainer>
      <ProfileSectionContainer
        sectionName={t('profile.personal.sections.experience')}
        isEmpty={dataExperiences.length <= 0}
        hideOnEmpty={false}
      >
        <PublicProfileAttachments data={dataExperiences} />
      </ProfileSectionContainer>
      <ProfileSectionContainer
        sectionName={t('profile.personal.sections.training')}
        isEmpty={dataTraining.length <= 0}
        hideOnEmpty={false}
      >
        <PublicProfileAttachments data={dataTraining} />
      </ProfileSectionContainer>
      <ProfileSectionContainer
        sectionName={t('profile.personal.sections.bankDetails')}
        isEmpty={dataBankDetails.length <= 0}
        hideOnEmpty={false}
      >
        <PublicProfileAttachments data={dataBankDetails} />
      </ProfileSectionContainer>
    </StyledPrivateProfileTabContentWrapper>
  );
};
