import React, { FC, useCallback } from 'react';
import { FormInstance } from 'antd/lib/form';
import { TType } from 'translations';
import moment from 'moment/moment';
import { GroupOfFormItem, SelectOptions } from 'types';
import {
  FORM_ITEM_REQUIRED_RULE,
  FORM_ITEM_20_CHARACTER_RULES,
  disableFutureDate,
  englishPatternRule,
  numberPatternRule,
  FORM_ITEM_10_CHARACTER_RULES,
  FORM_ITEM_MIN_10_CHARACTER_RULES,
} from 'utils';
import { WRAPPER_COL_SPAN_SIZE_21 } from 'ui/components';
import { StaticFieldsGroup } from '../../../FieldsGroup';

type Props = {
  t: TType;
  genderLookups: SelectOptions;
  maritalStatusLookups: SelectOptions;
  countriesLookups: SelectOptions;
  form?: FormInstance;
};

export const ProfileUpdatePersonalDetailsFormGroup: FC<Props> = ({
  t,
  genderLookups,
  maritalStatusLookups,
  countriesLookups,
  form,
}) => {
  const onBirthDataChange = useCallback(
    (date: moment.Moment | null) => {
      if (form && date) {
        const age = moment().diff(moment(date), 'years', false);
        form.setFieldsValue({ age });
      }
    },
    [form]
  );
  const personalDetailsFormGroup = useCallback<() => GroupOfFormItem[]>(() => {
    return [
      {
        'Personal Details': [
          {
            id: `${0}`,
            label: 'First name',
            name: 'firstName',
            type: 'input',
            rules: [
              ...FORM_ITEM_REQUIRED_RULE,
              ...FORM_ITEM_20_CHARACTER_RULES,
              englishPatternRule(t),
            ],
            wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
            placeholder: 'Enter your first name',
          },
          {
            id: `${1}`,
            label: "Father's name",
            name: 'fatherName',
            type: 'input',
            wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
            placeholder: "Enter father's name",
            rules: [...FORM_ITEM_20_CHARACTER_RULES, englishPatternRule(t)],
          },
          {
            id: `${2}`,
            label: "Grandfather's name",
            name: 'grandfatherName',
            type: 'input',
            wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
            placeholder: "Enter grandfather's name",
            rules: [...FORM_ITEM_20_CHARACTER_RULES, englishPatternRule(t)],
          },
          {
            id: `${3}`,
            label: 'Family name',
            name: 'lastName',
            type: 'input',
            rules: [
              ...FORM_ITEM_REQUIRED_RULE,
              ...FORM_ITEM_20_CHARACTER_RULES,
              englishPatternRule(t),
            ],
            wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
            placeholder: 'Enter family name',
          },
          {
            id: `${4}`,
            label: 'National/IQAMA ID',
            name: 'nationalId',
            type: 'input',
            rules: [
              ...FORM_ITEM_REQUIRED_RULE,
              numberPatternRule(t),
              ...FORM_ITEM_10_CHARACTER_RULES,
              ...FORM_ITEM_MIN_10_CHARACTER_RULES,
            ],
            disabled: true,
            wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
            placeholder: 'Enter national/iqama ID',
          },
          {
            id: `${5}`,
            label: 'Gender',
            name: 'gender',
            type: 'select',
            options: genderLookups,
            selectTheValueAsKey: true,
            rules: FORM_ITEM_REQUIRED_RULE,
            wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
            placeholder: 'Choose gender',
          },
          {
            id: `${6}`,
            label: 'Date of birth',
            name: 'dateOfBirth',
            type: 'date',
            disabledDate: disableFutureDate,
            rules: FORM_ITEM_REQUIRED_RULE,
            wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
            disabled: true,
            onDateChange: onBirthDataChange,
            placeholder: 'Choose date of birth',
          },
          {
            id: `${7}`,
            label: 'Age',
            name: 'age',
            type: 'input',
            disabled: true,
            rules: FORM_ITEM_REQUIRED_RULE,
            wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          },
          {
            id: `${8}`,
            label: 'Country of origin',
            name: 'countryId',
            type: 'select',
            options: countriesLookups,
            selectTheValueAsKey: true,
            rules: FORM_ITEM_REQUIRED_RULE,
            wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
            placeholder: 'Choose country of origin',
            filterOption: (input, option) =>
              ((option?.label as string) || option?.children || '')
                .toLowerCase()
                .includes(input.toLowerCase()),
          },
          {
            id: `${9}`,
            label: 'Marital status',
            name: 'maritalStatus',
            type: 'select',
            options: maritalStatusLookups,
            selectTheValueAsKey: true,
            rules: FORM_ITEM_REQUIRED_RULE,
            wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
            placeholder: 'Choose marital status',
          },
        ],
      },
    ];
  }, [genderLookups, maritalStatusLookups, countriesLookups, onBirthDataChange, t]);
  return (
    <StaticFieldsGroup
      key={'form-group-personal-details'}
      t={t}
      getFormFields={personalDetailsFormGroup}
      form={form}
      isShowSubTitle={false}
    />
  );
};
