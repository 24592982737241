import React, { useCallback, useEffect, useState } from 'react';
import { AppLayout } from 'layouts';
import { useHistory, useLocation } from 'react-router';
import { Header, useServicesContext } from 'app';
import { useTranslate } from 'translations';
import { TaskList, TitleH1, GroupsTasks } from 'ui';
import { Col, Row } from 'antd';
import { TaskCommentsDrawer } from 'ui/components/TaskCommentsDrawer';
import { profileData, useSelector } from 'store';
import { PagesUrls } from 'namespace';
import { StyledTab } from '../../Tasks/Tasks.styled';
import { useTasksContext } from '../../Tasks';

const { TabPane } = StyledTab;

export const MyWorkLayout = () => {
  const { t } = useTranslate();
  const { currentActiveTab, setCurrentActiveTab } = useTasksContext();
  const {
    setIsFilterByRequest,
    setPrettyIdInfoRedirection,
    isFilterByPrettyId,
    prettyIdInfoRedirection,
    setIsFilterByPrettyId,
    setRequestInfoRedirection,
  } = useServicesContext();

  const { data } = useSelector(profileData);
  const location = useLocation<{ defaultTab: string }>();
  const queryParams = location.search;
  const query = queryParams.split('=')[1];
  const [isQueryParams, setIsQueryParams] = useState<boolean>(false);
  const inbox = 'inbox';
  const todo = 'todo';
  const history = useHistory();

  const onTabClick = useCallback(
    (value: string) => () => {
      setCurrentActiveTab(value);
      setIsFilterByRequest(false);
      setRequestInfoRedirection({});
      setIsFilterByPrettyId(false);
      setPrettyIdInfoRedirection({});
      setIsQueryParams(false);
      history.replace(PagesUrls.MyWork);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      history,
      currentActiveTab,
      setCurrentActiveTab,
      setIsFilterByRequest,
      setPrettyIdInfoRedirection,
    ]
  );

  useEffect(() => {
    if (location?.state?.defaultTab) {
      setCurrentActiveTab(location?.state?.defaultTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.defaultTab]);

  useEffect(() => {
    if (isQueryParams && query === inbox) {
      setCurrentActiveTab('work-1');
    } else if (currentActiveTab === '0') {
      setCurrentActiveTab('work-0');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActiveTab, isQueryParams, query]);

  useEffect(() => {
    if (queryParams) {
      setIsQueryParams(query === inbox || query === todo);
    }
  }, [queryParams, query]);

  useEffect(() => {
    if (isFilterByPrettyId && prettyIdInfoRedirection?.redirectionTab) {
      setCurrentActiveTab(prettyIdInfoRedirection.redirectionTab);
    }
  }, [
    isFilterByPrettyId,
    prettyIdInfoRedirection,
    prettyIdInfoRedirection?.redirectionTab,
    setCurrentActiveTab,
  ]);

  return (
    <AppLayout displayFooter={false}>
      <Header>
        <Row align="middle">
          <Col>
            <TitleH1>{t('myWork.title')}</TitleH1>
          </Col>
        </Row>
      </Header>
      <StyledTab activeKey={currentActiveTab} className="layout-main">
        <TabPane
          tab={<span onClick={onTabClick('work-0')}>{t('tasks.tab.myTasks')}</span>}
          key="work-0"
        >
          <TaskList
            userId={data?.adOid}
            isQueryParams={isQueryParams}
            setIsQueryParams={setIsQueryParams}
          />
        </TabPane>
        <TabPane
          tab={<span onClick={onTabClick('work-1')}>{t('tasks.tab.myGroupsTasks')}</span>}
          key="work-1"
        >
          <GroupsTasks
            userId={data?.adOid}
            isQueryParams={isQueryParams}
            setIsQueryParams={setIsQueryParams}
          />
        </TabPane>
      </StyledTab>

      <TaskCommentsDrawer />
    </AppLayout>
  );
};
