import React, { FC, useMemo } from 'react';
import { Form, Row, Select } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { TType } from 'translations';
import { selectFilterOption } from 'utils/getFilterOptions';
import { FormField } from 'types';
import { StyledFieldLabel } from '../GeneratedForm.Styled';
import { useFieldPlaceholder } from '../GeneratedForm.hooks';
import { useFromFieldName, useGetFromFieldId } from '../../../hooks';

interface Props {
  field: FormField;
  t: TType;
  isMulti?: boolean;
  withLabel?: boolean;
  showSearch?: boolean;
  loading?: boolean;
  onSearch?: (value: string) => void;
  onSelect?: (value: string) => void;
  formListField?: FormListFieldData;
}

const { Option } = Select;

export const FieldSelect: FC<Props> = ({
  field,
  t,
  isMulti,
  withLabel,
  loading,
  onSearch,
  onSelect,
  formListField,
}) => {
  const options = useMemo(
    () =>
      field.type?.values
        ? Object.entries(field.type?.values).map(([value, label]) => ({ value, label }))
        : [],
    [field]
  );

  const rules = useMemo(
    () => [
      {
        required: field.validationConstraints.some((rule) => rule.name === 'required'),
        message: `${field.label} is required`,
      },
    ],
    [field.label, field.validationConstraints]
  );

  const placeholder = useFieldPlaceholder({
    placeholder: field.placeholder,
    label: field.label,
    placeholderPrefix: 'Choose',
  });

  const getFieldId = useGetFromFieldId();
  const fieldId = getFieldId(field.id);

  const name = useFromFieldName(field.id, formListField);

  const initialValue = useMemo(() => {
    if (field.initialValue) {
      const initValue = options.filter(
        (option) =>
          option.value.toString().toUpperCase() === field.initialValue?.toString()?.toUpperCase()
      )[0]?.value;
      if (initValue && onSelect) {
        onSelect(initValue);
      }
      return initValue;
    }
    return undefined;
  }, [field.initialValue, onSelect, options]);

  return (
    <>
      {withLabel && (
        <Row>
          <StyledFieldLabel>{field.label}</StyledFieldLabel>
        </Row>
      )}
      <Form.Item
        {...formListField}
        hasFeedback
        id={fieldId}
        label={withLabel ? null : field.label}
        name={name}
        rules={rules}
        initialValue={initialValue}
        dependencies={field.properties?.dependencies}
        getValueProps={(value) => {
          const selectedValues = options.filter(
            (option) => option.value.toString().toUpperCase() === value?.toString()?.toUpperCase()
          );
          const selectedValue = selectedValues[0];
          if (!isMulti && selectedValue && onSelect) {
            onSelect(selectedValue.value);
          }
          return selectedValues;
        }}
      >
        <Select
          disabled={field.properties.viewOnly === 'true'}
          getPopupContainer={(trigger) => trigger.parentElement}
          notFoundContent={t('dropdown.noMatching')}
          showSearch
          filterOption={field.properties?.onSearch ? false : selectFilterOption}
          mode={isMulti ? 'multiple' : undefined}
          allowClear={isMulti}
          placeholder={placeholder}
          loading={loading || field.properties?.loading}
          onSearch={onSearch ?? field.properties?.onSearch}
          onSelect={onSelect}
        >
          {options.map((option, index) => (
            <Option key={index} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};
