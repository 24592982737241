import React, { memo, FC } from 'react';
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import { TasksProvider } from './TasksProvider';
import { TasksLayout } from './TasksLayout';
import { ServiceNewRequest } from './components';

type Props = RouteComponentProps;

export const TasksComponent: FC<Props> = ({ match: { path } }) => (
  <TasksProvider>
    <Switch>
      <Route
        path={[
          `${path}/:processDefinitionKey?/:isDynamicForm?/new-request/:jiraServiceDeskId?/:jiraRequestTypeId?`,
          `${path}/:processDefinitionKey?/:isDynamicForm?/edit-request/:taskId?/:processId?`,
        ]}
        exact
      >
        <ServiceNewRequest />
      </Route>
      <Route path={path}>
        <TasksLayout />
      </Route>
    </Switch>
  </TasksProvider>
);

export const Tasks = memo(withRouter(TasksComponent));
