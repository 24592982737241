import { UploadFile } from 'antd/lib/upload/interface';
import { AttachmentStandard, AttachmentProperties } from 'types';
import { BYTES_IN_MB, ACCEPT_FILE_EXTENSIONS } from './constants';

export const validateFileSize = (sizeInBytes: number, allowedFileSizeInMB?: number) =>
  allowedFileSizeInMB ? sizeInBytes / BYTES_IN_MB < allowedFileSizeInMB : true;

const getFileExtension = (fileName: string) => fileName.split('.').pop() || '';

export const validateFileType = ({ type, name }: UploadFile, allowedTypes?: string) => {
  if (!allowedTypes) {
    return true;
  }

  if (type) {
    return allowedTypes.includes(type);
  }

  return ACCEPT_FILE_EXTENSIONS.includes(getFileExtension(name));
};

export const getGroupedAttachmentData = (attachmentStandardData: AttachmentStandard[]) => {
  return attachmentStandardData.reduce(
    (acc, { propertyName, propertyValue }) => ({
      ...acc,
      [propertyName]: propertyValue,
    }),
    {} as AttachmentProperties
  );
};
