import { FormFields } from 'types';

export const nonPoBudgetTeamSubmitFormFields = (
  data: {
    glAccounts?: Record<string, string>;
    costCenterAccounts?: Record<string, string>;
    projectAccounts?: Record<string, string>;
  },
  status: {
    glAccountsLoading: boolean;
    costCenterAccountsLoading: boolean;
    projectAccountsLoading: boolean;
  },
  search: {
    onGlAccountsSearch: (value: string) => void;
    onCostCenterAccountsSearch: (value: string) => void;
    onProjectAccountsSearch: (value: string) => void;
  },
  readOnly: boolean
): FormFields => {
  return [
    {
      businessKey: false,
      id: 'description',
      label: 'Task Description',
      type: {
        name: 'text',
      },
      defaultValue: 'Please review the request details and provide your approval on the request.',
      value: {
        value: 'Please review the request details and provide your approval on the request.',
        type: {
          name: 'string',
        },
      },
      validationConstraints: [],
      properties: {
        viewOnly: 'true',
        requiredField: false,
      },
      typeName: 'text',
    },
    {
      businessKey: false,
      id: 'budgetYear',
      label: 'Budget Year',
      placeholder: 'Enter the budget year',
      type: {
        name: 'string',
      },
      defaultValue: null,
      value: {
        value: null,
        type: {
          name: 'string',
        },
      },
      validationConstraints: readOnly
        ? []
        : [
            { name: 'required', configuration: null },
            { name: 'pattern', configuration: /^(20)\d{2}$/ },
          ],
      properties: {
        requiredField: true,
        viewOnly: readOnly ? 'true' : 'false',
      },
      typeName: 'string',
    },
    {
      businessKey: false,
      id: 'glAccount',
      label: 'GL Account',
      placeholder: 'Choose GL account',
      type: {
        name: 'enum',
        values: data.glAccounts,
      },
      defaultValue: null,
      value: {
        value: null,
        type: {
          name: 'enum',
        },
      },
      validationConstraints: readOnly ? [] : [{ name: 'required', configuration: null }],
      properties: {
        requiredField: true,
        onSearch: search.onGlAccountsSearch,
        loading: status.glAccountsLoading,
        viewOnly: readOnly ? 'true' : 'false',
      },
      typeName: 'enum',
    },
    {
      businessKey: false,
      id: 'costCenter',
      label: 'Cost Center',
      placeholder: 'Choose cost center',
      type: {
        name: 'enum',
        values: data.costCenterAccounts,
      },
      defaultValue: null,
      value: {
        value: null,
        type: {
          name: 'enum',
        },
      },
      validationConstraints: readOnly ? [] : [{ name: 'required', configuration: null }],
      properties: {
        requiredField: true,
        onSearch: search.onCostCenterAccountsSearch,
        loading: status.costCenterAccountsLoading,
        viewOnly: readOnly ? 'true' : 'false',
      },
      typeName: 'enum',
    },
    {
      businessKey: false,
      id: 'project',
      label: 'Project',
      placeholder: 'Choose project',
      type: {
        name: 'enum',
        values: data.projectAccounts,
      },
      defaultValue: null,
      value: {
        value: null,
        type: {
          name: 'enum',
        },
      },
      validationConstraints: readOnly ? [] : [{ name: 'required', configuration: null }],
      properties: {
        requiredField: true,
        onSearch: search.onProjectAccountsSearch,
        loading: status.projectAccountsLoading,
        viewOnly: readOnly ? 'true' : 'false',
      },
      typeName: 'enum',
    },
    {
      businessKey: false,
      id: 'feedBack',
      label: 'Feedback',
      type: {
        name: 'textArea',
      },
      defaultValue: null,
      value: {
        value: null,
        type: {
          name: 'string',
        },
      },
      validationConstraints: [],
      properties: {
        maxlength: '1024',
        requiredField: false,
        viewOnly: readOnly ? 'true' : 'false',
      },
      typeName: 'textArea',
    },
    {
      businessKey: false,
      id: 'attachment',
      label: 'Attachments',
      type: {
        name: 'file',
      },
      defaultValue: null,
      value: {
        value: null,
        type: {
          name: 'string',
        },
      },
      validationConstraints: [],
      properties: {
        maxAttachementsCount: '3',
        boldLabel: 'true',
        requiredField: false,
        viewOnly: readOnly ? 'true' : 'false',
      },
      typeName: 'file',
    },
  ];
};

export const nonPoBudgetTeamSubmitFormActionFields = (
  onReject: () => void,
  isLoading?: boolean
): FormFields => [
  {
    businessKey: false,
    id: 'approved',
    label: 'Approve',
    type: {
      name: 'button',
    },
    defaultValue: null,
    value: {
      value: false,
      type: {
        name: 'boolean',
      },
    },
    validationConstraints: [],
    properties: {
      requiredField: false,
      primeColor: 'true',
      actionButtonLoading: isLoading,
    },
    typeName: 'button',
  },
  {
    businessKey: false,
    id: 'rejected',
    label: 'Reject',
    type: {
      name: 'button',
    },
    defaultValue: null,
    value: {
      value: false,
      type: {
        name: 'boolean',
      },
    },
    validationConstraints: [],
    properties: {
      requiredField: false,
      primeColor: 'false',
      actionButtonHtmlType: 'button',
      onActionButtonClick: onReject,
      actionButtonLoading: isLoading,
    },
    typeName: 'button',
  },
];
