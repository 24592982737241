import React from 'react';
import { Row, Col } from 'antd';
import { MessagesTypes, TType } from 'translations';
import { useHistory } from 'react-router-dom';
import { StyledAppCard } from '../AppsDrawer.styled';
import { AppDrawerContent } from '../AppDrawerContent';
import { AppNameStyle } from './AppName';

interface Props {
  onClickHandler?: () => void;

  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  iconURL?: string | null;
  appName: string;
  isExternalApp: boolean;
  t: TType;
  linkTo?: string;
  webUrl?: string;
  query?: string;
}

const AppCardComponent: React.FC<Props> = ({
  onClickHandler,
  isExternalApp,
  t,
  appName,
  Icon,
  linkTo,
  iconURL,
  webUrl,
  query,
}) => {
  const history = useHistory();
  const handleOnClick = React.useCallback(() => {
    if (onClickHandler && linkTo) {
      onClickHandler();
      const location: { pathname: string; hash?: string } = {
        pathname: linkTo,
      };
      if (query) {
        location['hash'] = query;
      }
      history.replace(location);
    } else if (isExternalApp && iconURL) {
      window.open(webUrl, '_blank');
    }
  }, [onClickHandler, linkTo, isExternalApp, iconURL, query, history, webUrl]);

  return (
    <React.Fragment>
      <StyledAppCard onClick={handleOnClick}>
        <Row>
          {isExternalApp && iconURL ? (
            <AppDrawerContent iconURL={iconURL} appName={appName} />
          ) : (
            Icon && (
              <>
                <Col span={24}>
                  <Icon />
                </Col>
                <Col span={24}>{AppNameStyle(t(`${appName}` as MessagesTypes))}</Col>
              </>
            )
          )}
        </Row>
      </StyledAppCard>
    </React.Fragment>
  );
};

export const AppCard = React.memo(AppCardComponent);
