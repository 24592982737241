import styled from 'styled-components';
import { colors, space } from 'ui/elements';
import { Row } from 'antd';

export const StyledRequestDetailsWraper = styled(Row)`
  width: 667px;
`;

export const StyledCustomFormWraper = styled(Row)`
  width: 720px;
`;
export const StyledCustomFormGroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: ${space.normal};
`;

export const StyledCustomFormGroupInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${colors.backgroundGrey};
  padding: 24px 12px 12px 24px;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: unset;
  }
`;

export const StyledFormHeaderWrapper = styled.div`
  margin-bottom: ${space.comfy};
`;
