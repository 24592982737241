import React, { FC } from 'react';
import { Col, Image } from 'antd';
import { span } from 'ui/elements';
import { AppNameStyle } from './Components';

interface Props {
  iconURL: string | null;
  appName: string;
}
const AppDrawerContentComponent: FC<Props> = ({ iconURL, appName }) => {
  const { span24 } = span;
  return (
    <React.Fragment>
      <Col span={span24}>
        {iconURL && <Image height={40} src={iconURL} alt={appName} preview={false} />}
      </Col>

      <Col span={span24}>{AppNameStyle(appName)}</Col>
    </React.Fragment>
  );
};

export const AppDrawerContent = React.memo(AppDrawerContentComponent);
