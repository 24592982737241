import React, { FC, useMemo } from 'react';
import { useTranslate } from 'translations';
import { useProfileOnGoingRequest, useProfilePersonalData } from 'api';
import moment from 'moment';
import {
  IProfileDependentDetail,
  IProfileEmergencyContact,
  IPersonalDetail,
  INewProfileOverview,
  IOngoingRequestInfo,
} from 'types';
import { ProfileSectionContainer } from 'ui';
import {
  PrivateProfileDependentDetails,
  PrivateProfileEmergencyContact,
  PrivateProfilePersonalDetails,
  ProfileOverview,
  OnGoingRequestSection,
  PrivateProfilePrivacyNotice,
} from '../ProfilePersonalDetails';
import { GuardLockIcon, PersonLockIcon } from '../../../../assets';
import { StyledPrivateProfileTabContentWrapper } from './PrivateProfileTabs.styled';

export const PrivateProfilePersonalTab: FC = () => {
  const { t } = useTranslate();
  const { data: personalData, isLoading, error } = useProfilePersonalData();
  const {
    data: requestData,
    isLoading: isLoadingOnGoingRequest,
    isError: isErrorOnGoingRequest,
  } = useProfileOnGoingRequest();

  const overView: INewProfileOverview = useMemo(() => {
    return {
      location: personalData?.location,
      position: personalData?.job,
      department: personalData?.department,
      mangerEmail: personalData?.managerEmail,
      mangerPersonHcmid: personalData?.managerPersonHcmid,
      managerName: personalData?.managerName,
    };
  }, [personalData]);

  const personalDetails: IPersonalDetail = useMemo(() => {
    const age = (): number => {
      const dob = moment(personalData?.dateOfBirth, 'YYYY-MM-DD');
      return moment().diff(dob, 'year');
    };
    return {
      firstName: personalData?.firstName,
      fatherName: personalData?.fatherName,
      grandfatherName: personalData?.grandfatherName,
      familyName: personalData?.lastName,
      idNumber: personalData?.employeeId,
      nationalId: personalData?.nationalId,
      gender: personalData?.gender,
      age: age(),
      dateOfBirth: moment(personalData?.dateOfBirth).format('DD.MM.YYYY'),
      countryOfOrigin: personalData?.country,
      maritalStatus: personalData?.maritalStatus,
    };
  }, [personalData]);

  const dependentDetails: IProfileDependentDetail[] = useMemo(() => {
    return personalData?.dependentDetails ?? [];
  }, [personalData]);

  const emergencyContacts: IProfileEmergencyContact[] = useMemo(() => {
    return personalData?.emergencyContacts ?? [];
  }, [personalData]);

  return (
    <StyledPrivateProfileTabContentWrapper>
      {!isLoadingOnGoingRequest && !isErrorOnGoingRequest && (
        <ProfileSectionContainer>
          <OnGoingRequestSection requestData={requestData as IOngoingRequestInfo} />
        </ProfileSectionContainer>
      )}
      <ProfileSectionContainer
        isLoading={isLoading}
        isEmpty={!!error}
        sectionName={t('profile.personal.sections.overview')}
      >
        <ProfileOverview t={t} overview={overView} />
      </ProfileSectionContainer>
      <ProfileSectionContainer
        sectionName={t('profile.tab.personal')}
        isLoading={isLoading}
        isEmpty={!!error}
      >
        <PrivateProfilePersonalDetails t={t} personalDetails={personalDetails} />
      </ProfileSectionContainer>
      <ProfileSectionContainer
        sectionName={t('profile.personal.sections.dependentDetails')}
        isLoading={isLoading}
        isEmpty={error ? true : dependentDetails?.length <= 0}
        hideOnEmpty={true}
      >
        <PrivateProfileDependentDetails t={t} dependentDetails={dependentDetails} />
      </ProfileSectionContainer>
      <ProfileSectionContainer
        sectionName={t('profile.personal.sections.emergencyContact')}
        isLoading={isLoading}
        isEmpty={error ? true : emergencyContacts?.length <= 0}
      >
        <PrivateProfileEmergencyContact t={t} emergencyContacts={emergencyContacts} />
      </ProfileSectionContainer>
      <ProfileSectionContainer>
        <PrivateProfilePrivacyNotice
          icon={<PersonLockIcon />}
          link="https://www.tawal.com.sa/en/privacy-notice"
          title="Read our Privacy Policy"
          t={t}
        />
      </ProfileSectionContainer>
      <ProfileSectionContainer>
        <PrivateProfilePrivacyNotice
          icon={<GuardLockIcon />}
          link="https://dsr.tawal.com.sa"
          title="Raise a Data Subject Request"
          t={t}
        />
      </ProfileSectionContainer>
    </StyledPrivateProfileTabContentWrapper>
  );
};
